<template>
  <form @submit.prevent="submitForm">
    <!-- Form for adding a new location -->
    <div class="box">
      <h1 class="is-title is-size-3">Add New Location</h1>
      <div class="field">
        <div class="is-flex is-flex-direction-column has-text-left">
          <div class="is-flex is-flex-direction-row">
            <div class="label is-pulled-left is-text">Address</div>
            <div class="is-text has-red-text is-pulled-left ml-3">
              (Required if no lat/long)
            </div>
          </div>
          <div class="is-size-7 is-italic mb-3 is-text">
            Formatting guidelines can be found here:
            <a
              href="https://docs.mapbox.com/help/troubleshooting/address-geocoding-format-guide/"
              >MapBox Geocoding</a
            >
          </div>
        </div>
        <div class="control">
          <b-input
            ref="addr"
            :id="'addr'"
            :placeholder="'123 Main St Boston MA 02111'"
            :maxLength="100"
            @valChanged="valueChanged"
          ></b-input>
        </div>
      </div>
      <div class="field">
        <div class="label is-pulled-left is-text">Latitude and Longitude</div>
        <div class="is-text has-red-text is-pulled-left ml-3">
          (Required if no address)
        </div>
        <div class="control">
          <b-input
            ref="lat"
            :id="'lat'"
            :placeholder="'Enter latitude here'"
            :maxLength="30"
            @valChanged="valueChanged"
            :isNumberOnly="true"
          ></b-input>

          <b-input
            ref="long"
            :id="'long'"
            :placeholder="'Enter longitude here'"
            :maxLength="30"
            @valChanged="valueChanged"
            :isNumberOnly="true"
            class="mt-2"
          ></b-input>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <label class="checkbox is-pulled-left is-text">
            <input ref="table" type="checkbox" />
            Show on table
          </label>
        </div>
        <div class="column">
          <label class="checkbox is-pulled-left is-text">
            <input ref="mobile" type="checkbox" />
            Show on mobile
          </label>
        </div>

        <div class="column">
          <div class="buttons">
            <button
              class="button is-primary is-pulled-right is-text"
              style="text-decoration: none"
              :class="{ disabled: !canSubmit }"
            >
              Add New
            </button>
            <button
              class="button is-primary is-outlined is-pulled-right is-text"
              style="text-decoration: none"
              type="button"
              @click="this.$emit('close')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import BInput from "../../components/b-input.vue";
export default {
  components: { BInput },
  data() {
    return {
      location: {},
      canSubmit: false,
    };
  },
  computed: {
    collectionID() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.location.show_mobile = false;
    this.location.show_table = false;
  },
  methods: {
    valueChanged() {
      this.canSubmit =
        this.$refs.addr.getValue() != "" ||
        (this.$refs.lat.getValue() != "" && this.$refs.long.getValue() != "");
    },
    async submitForm() {
      try {
        this.location.address = this.$refs.addr.getValue();
        this.location.latitude = this.$refs.lat.getValue();
        this.location.longitude = this.$refs.long.getValue();
        this.location.show_mobile = this.$refs.mobile.checked;
        this.location.show_table = this.$refs.table.checked;

        var loc = await this.$store.dispatch("locations/create", {
          collectionId: this.collectionID,
          location: this.location,
        });

        if (loc != null) {
          this.$router.push({
            name: "Edit Location",
            params: {
              id: this.collectionID,
              location_id: loc.id,
            },
          });
        }
      } catch (error) {
        this.$store.dispatch("showErrorMessage", error);
      }
    },
  },
};
</script>