<template>
  <div class="mt-6" style="max-width:100vw;min-height:100vh;">
    <h1 class="panel-heading is-header">{{ collection.title }}</h1>
    

    <div class="columns is-centered p-0 m-0" style="max-width:100%">
    <div  class="column is-three-quarters-desktop is-full-mobile">
      <p class="m-3 is-text has-text-left">{{ collection.description }}</p>
      <a v-if="loggedIn" class="button is-primary is-text" style="text-decoration:none"  @click="AddLocation">Add Location </a>
      <div v-if="showNewForm" class="mt-3">
        <AddLocationForm @close="showNewForm = false"/>
      </div>

      <div class="section">
        <div class="columns is-multiline">
          <div
            class="column is-one-quarter-desktop is-full-mobile"
            v-for="location in locations"
            :key="location.id"
            
          >
            <Location :location="location" @selectedLocation="ShowRecord" @deleteLocation="DeleteLocation" />
          </div>
        </div>
      </div>

      <b-modal
      :visible="showModal"
      heading="Deleting Location"
      :subHeading="'Are you sure you want to delete this location?\n' + selectedLocation.address"
      confirmText="Delete"
      colorStyle="danger"
      @cancel="showModal = false"
      @confirm="ForceDeleteLocation"
      />
    </div>
    </div>
  </div>
</template>

<script>
import Location from "../locations/location.vue";
import AddLocationForm from "../locations/AddLocationForm.vue";
import BModal from '../../components/b-modal.vue';

export default {
  data(){
    return{
      showNewForm: false,
      selectedLocation:{},
      showModal:false
    }
  },
  components: {
    Location,
    AddLocationForm,
    BModal
  },
  async mounted() {
    try{
      this.$store.commit("AWAIT_RESPONSE", true);
      const collectionId = this.$route.params.id;
      await this.$store.dispatch("locations/getCollectionLocations", collectionId);
      this.$store.commit("AWAIT_RESPONSE", false);
    }
    catch(error){
      this.$store.dispatch("showErrorMessage", error);
    }
  },

  computed: {
    collection() {
      const c = this.$store.state.collections.activeCollection;
      if (!c.id) {
        //if no active collection set one from route (in case of refresh)
        const collectionId = this.$route.params.id;
        this.$store.dispatch("collections/setCollection", collectionId);
      }
      return c;
    },
    locations() {
      var ls = this.$store.state.locations.all.items;
      if(!this.loggedIn) ls = this.$store.getters['locations/getMobileLocations'];

      if(ls.length > 0) ls.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
      return ls;
      // if(this.loggedIn) return this.$store.state.locations.all.items;
      // return this.$store.getters['locations/getMobileLocations'];
    },
    loggedIn: function(){
        return this.$store.getters.isAuthenticated;
      }
  },
  methods: {
  //   onScroll(){
  //   console.log("scrolling")
  // },
    GetImage: function (relativePath) {
      // Need to add s3 bucket logic
      return relativePath;
    },
    ShowRecord: function (location) {
      this.$store.commit("locations/setActiveLocation", location)
      const path = "/collections/"+ this.collection.id + "/locations/" + location.id +"/records/"
      this.$router.push({path});
    },
    AddLocation: function(){
      this.showNewForm = true;
    },
    DeleteLocation: function(location){
      this.selectedLocation = location;
      this.showModal = true;
    },
    async ForceDeleteLocation(){
      try{
        this.$store.commit("AWAIT_RESPONSE", true);
        await this.$store.dispatch("locations/destroy", this.selectedLocation);
        await this.$store.dispatch("locations/getCollectionLocations", this.collection.id);

        this.selectedLocation = {};
        this.showModal = false;

        this.$store.commit("AWAIT_RESPONSE", false);
      }
      catch(error){
        this.$store.dispatch("showErrorMessage", error);
      }
      
    }
  },
};
</script>