<template>
  <div class="card">
    <div class="card-content">
      <div class="is-flex is-flex-direction-column">
        <div class="is-flex is-flex-direction-row is-fullwidth">
          <figure class="image" style="width: 15%">
            <img
              style="width: 100%"
              :src="require('@/assets/location_marker.png')"
              alt=""
            />
          </figure>
          <p v-if="hasAddress" class="is-title column has-text-left title is-size-6">
            {{ location.address }}
          </p>
          <p v-else class="is-title column has-text-left title is-size-6">
            Address Unknown ({{ location.latitude }}, {{ location.longitude }})
          </p>

          <!-- Published status indicator -->
          <figure v-if="loggedIn" class="image" style="width: 5%">
            <img
              style="width: 100%"
              :src="location.show_mobile ? require('@/assets/show_mobile.png') : require('@/assets/hide_mobile.png')"
              :title="'show_mobile: '+this.location.show_mobile"
            />
          </figure>
          <figure v-if="loggedIn" class="image" style="width: 5%">
            <img
              style="width: 100%"
              :src="location.show_table ? require('@/assets/show_table.png') : require('@/assets/hide_table.png')"
              :title="'show_table: '+this.location.show_table"
            />
          </figure>
        </div>

        <!-- preview of related records, show first 3 -->
        <div v-if="loggedIn">
          <button
            class="button is-small"
            v-if="!ShowRecords"
            @click="GetAndShowRecords"
          >
          Show Records
          </button>
          <div v-else>
            <button
            class="button is-small"
            @click="ShowRecords = false"
            >
            Hide Records
            </button>
            <div class="my-2" style="overflow-y:auto; max-height:10vh">
            <div v-for="record in records" :key="record.id">
              <div class="is-flex is-flex-direction-row is-fullwidth">
                <figure class="image ml-4 my-2 is-flex" style="min-width:5%;max-width:10%; vertical-align:middle">
                  <img
                    style="margin:auto;"
                    :src="imgSource(record)"
                  />
                </figure>
                <p class="column has-text-left is-text">{{ record.object_id }}</p>
              </div>
            </div>
            </div>
          </div>
        </div>

        <!-- QR Code -->
        <!-- <div class="is-flex is-flex-direction-row is-fullwidth is-justify-content-right">
          <figure class="image" style="width: 10%">
            <img style="width: 100%" :src="this.url" alt="Placeholder image" />
          </figure>
        </div> -->
      </div>
    </div>

    <div class="card-footer">

      <div
      class="button is-text card-footer-item is-clipped" 
      style="text-decoration:none;width:30%;"
      @click.prevent="selectLocation"
      >
      <p class="is-size-6">View Gallery</p>
      </div>
      <div
        class="button is-text card-footer-item is-clipped"
        style="text-decoration:none; width:30%;"
        @click.prevent="editLocation"
        v-if="loggedIn"
        >
        <p class="is-size-6">Edit Location</p>
        </div
      >
      <div
        class="button is-text card-footer-item is-clipped"
        style="text-decoration:none; color:#FF0000; width:30%"
        @click.prevent="deleteLocation"
        v-if="loggedIn"
        >
        <p class="is-size-6">Delete</p>
        </div
      >
      </div>

  </div>
</template>

<script>
export default {
  props: ["location"],
  data() {
    return {
      url: "",
      records: [],
      ShowRecords: false
    };
  },
  mounted() {
    if (this.location) {
      let blob = new Blob([this.location.qr_code], { type: "image/svg+xml" });
      this.url = URL.createObjectURL(blob);
    }
  },
  computed: {
    loggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    hasAddress() {
      return this.location.address != "" && this.location.address != null;
    },
    locationString(){
        if(this.hasAddress){
          return this.location.address;
        }
        else{
          return this.location.latitude + ", "+ this.location.longitude;
        }
    }
  },
  methods: {
    async GetAndShowRecords(){
      if(this.records.length <= 0){
        //get records
        this.records = await this.$store.dispatch("records/getLocationRecords", this.location)
      }
      
      this.ShowRecords = true;
    },
    async selectLocation() {
      //check first to see if any records exist
      if(this.records.length <= 0){
        //get records
        this.records = await this.$store.dispatch("records/getLocationRecords", this.location)
      }

      if(this.records.length > 0) this.$emit("selectedLocation", this.location);
      else{
        this.$store.dispatch("showErrorMessage", this.loggedIn ? "Location '"+this.locationString+"' doesn't have any records yet. Add records by clicking 'Edit Location'." : "Gallery currently unavailable");
      }
    },
    editLocation: function () {
      //console.log(this.location.id);
      this.$router.push({
        name: "Edit Location",
        params: {
          id: this.location.collection_id,
          location_id: this.location.id,
        },
      });
    },
    deleteLocation: function () {
      this.$emit("deleteLocation", this.location);
      //this.$store.dispatch("locations/destroy", this.location );
    },
    recordExists(idx) {
      return (
        this.records != null &&
        this.records.length > 0 &&
        idx < this.records.length - 1
      );
    },
    imgSource(record){
      return record.thumbnail != '' && record.thumbnail != null ? record.thumbnail : require('@/assets/smhm_logo.jpg')
    }
  },
};
</script>